import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar/Navbar';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Footer from '../sections/Footer/Footer';
import HeroText from '../sections/HeroText/HeroText';
import entryAnimation from '../resources/entry-animation';

// markup
const IndexPage = () => {
    const navbarBreakpointRef = useRef();
    const data = useStaticQuery(query);
    const mainRef = useRef(null);
    entryAnimation(mainRef);
    return (
        <main ref={mainRef}>
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>Polityka Prywatności | GetHero</title>
            </Helmet>
            <HeroText header={`#${data.allPrivacyPolicyPage.nodes[0].header}`} />
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <section className="privacy-policy">
                <div className="row">
                    <div className="col-lg-10 offset-lg-2">
                        <div className="privacy-policy__subheader">
                            {data.allPrivacyPolicyPage.nodes[0].subheader}
                        </div>
                        <hr className="privacy-policy__line" />
                    </div>
                </div>
                {data.allPrivacyPolicyPage.nodes[0].articles.map((article, index) => (
                    <div className="row py-4" key={index}>
                        <div className="col-lg-2 privacy-policy__article-header">{article.header}</div>
                        <div
                            className="col-lg-10"
                            dangerouslySetInnerHTML={{ __html: article.text.replace('f1c40f', 'FECD32') }}
                        />
                    </div>
                ))}
            </section>
            <Footer />
            <ScrollTop />
        </main>
    );
};

const query = graphql`
    query PrivacyPolicySettings {
      allPrivacyPolicyPage {
        nodes {
          header
          subheader
          articles {
            header
            text
          }
        }
      }
    }`;

export default IndexPage;
